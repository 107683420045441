// Generated by Framer (e1a43d5)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";

const enabledGestures = {ehlBLnv3p: {hover: true}};

const serializationHash = "framer-9x7lT"

const variantClassNames = {ehlBLnv3p: "framer-v-135z33n"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const numberToPixelString = (value) => {
    if (typeof value !== "number") return value;
    if (!Number.isFinite(value)) return undefined;
    return Math.max(0, value) + "px";
};


const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({click, height, id, width, ...props}) => { return {...props, Iw89Pz8AL: click ?? props.Iw89Pz8AL} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;click?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, Iw89Pz8AL, dG7P1RNw0qNTBzVPUk, W_zkkLhPuqNTBzVPUk, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "ehlBLnv3p", enabledGestures, ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap824psy = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (Iw89Pz8AL) {const res = await Iw89Pz8AL(...args);
if (res === false) return false;}
})

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><Link href={{webPageId: "KDtxaRgl5"}} motionChild nodeId={"ehlBLnv3p"} openInNewTab={W_zkkLhPuqNTBzVPUk} scopeId={"AT44EgBs4"} smoothScroll><motion.a {...restProps} {...gestureHandlers} className={`${cx(scopingClassNames, "framer-135z33n", className, classNames)} framer-1w44ku5`} data-border data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"ehlBLnv3p"} onTap={onTap824psy} ref={refBinding} style={{"--border-bottom-width": "1px", "--border-color": "rgba(158, 161, 186, 0.25)", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", "--elm8qu": numberToPixelString(dG7P1RNw0qNTBzVPUk), backgroundColor: "rgb(255, 255, 255)", borderBottomLeftRadius: 8, borderBottomRightRadius: 8, borderTopLeftRadius: 8, borderTopRightRadius: 8, boxShadow: "none", ...style}} variants={{"ehlBLnv3p-hover": {"--border-color": "rgb(85, 64, 247)", boxShadow: "0px 1px 2px 0px rgba(0,0,0,0.25)"}}} {...addPropertyOverrides({"ehlBLnv3p-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7SW5zdHJ1bWVudCBTYW5zLTUwMA==", "--framer-font-family": "\"Instrument Sans\", \"Instrument Sans Placeholder\", sans-serif", "--framer-font-weight": "500", "--framer-line-height": "1.5em", "--framer-text-color": "var(--extracted-r6o4lv, rgb(0, 6, 35))"}}>Get in Touch</motion.p></React.Fragment>} className={"framer-sajzvj"} data-framer-name={"Text"} fonts={["GF;Instrument Sans-500"]} layoutDependency={layoutDependency} layoutId={"b_cW5ZkAj"} style={{"--extracted-r6o4lv": "rgb(0, 6, 35)", "--framer-paragraph-spacing": "0px"}} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-9x7lT.framer-1w44ku5, .framer-9x7lT .framer-1w44ku5 { display: block; }", ".framer-9x7lT.framer-135z33n { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 6px; height: 30px; justify-content: center; overflow: hidden; padding: var(--elm8qu); position: relative; text-decoration: none; width: 135px; will-change: var(--framer-will-change-override, transform); }", ".framer-9x7lT .framer-sajzvj { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-9x7lT.framer-135z33n { gap: 0px; } .framer-9x7lT.framer-135z33n > * { margin: 0px; margin-left: calc(6px / 2); margin-right: calc(6px / 2); } .framer-9x7lT.framer-135z33n > :first-child { margin-left: 0px; } .framer-9x7lT.framer-135z33n > :last-child { margin-right: 0px; } }", ".framer-9x7lT[data-border=\"true\"]::after, .framer-9x7lT [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 30
 * @framerIntrinsicWidth 135
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"v7tsvXk5S":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"Iw89Pz8AL":"click"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerAT44EgBs4: React.ComponentType<Props> = withCSS(Component, css, "framer-9x7lT") as typeof Component;
export default FramerAT44EgBs4;

FramerAT44EgBs4.displayName = "BOTÃO DO FAQ";

FramerAT44EgBs4.defaultProps = {height: 30, width: 135};

addPropertyControls(FramerAT44EgBs4, {Iw89Pz8AL: {title: "Click", type: ControlType.EventHandler}} as any)

addFonts(FramerAT44EgBs4, [{explicitInter: true, fonts: [{family: "Instrument Sans", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/instrumentsans/v1/pximypc9vsFDm051Uf6KVwgkfoSxQ0GsQv8ToedPibnr-yp2JGEJOH9npST3-QfwmS0v3_7Y.woff2", weight: "500"}]}], {supportsExplicitInterCodegen: true})